<template>
    <div>
        <CModalExtended
            size="lg"
            color="dark"
            :closeOnBackdrop="false"
            :show.sync="$store.state.unidadmedida.modalUnitMeasureType"
            :title="titleModalUnitMeasureType"
            class="modal-extended"
        >
            <CRow>
                <CCol sm="11" lg="12" xl="12">
                    <CInput
                        :label="$t('label.name')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7'}"                            
                        v-uppercase
                        :placeholder="$t('label.measurementUnitTypeName')"
                        v-model="$v.formTpUnidadMedida.NbTpUnidadMedida.$model"
                        :is-valid="hasError($v.formTpUnidadMedida.NbTpUnidadMedida)" 
                        :invalid-feedback="errorMessage($v.formTpUnidadMedida.NbTpUnidadMedida)"
                        required 
                        >
                        </CInput>               
                </CCol>
                <CCol sm="11" lg="12" xl="12">
                    <CSelect
                        :label="$t('label.status')"
                        addLabelClasses="required text-right"
                        :horizontal="{ label: 'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11 col-lg-7 col-xl-7'}"                
                        v-if="!idIndentification"
                        v-model="formTpUnidadMedida.FgActTpUnidad"
                        :value.sync="formTpUnidadMedida.FgActTpUnidad"
                        :options="selectOptions"
                        :is-valid="formTpUnidadMedida.FgActTpUnidad" 
                    />
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateLoading"
                >                 
                    <div v-if="!apiStateLoading && !loading">
                        <CIcon name="checkAlt"/>&nbsp;
                       {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    color="wipe"
                    @click="closeModal"
                    
                    :disabled="apiStateLoading || loading "
                >
                <CIcon name="x"/>&nbsp;
                     {{$t('button.cancel')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';
    import { FormTpUnidadMedida } from '@/_validations/unidadmedida/UnidadValidations';
    import UpperCase  from '@/_validations/uppercase-directive';
    import general from '@/_mixins/general';
    import modalMixin from '@/_mixins/modal';

    //data
    function data() {
        return {
            originalAct:'',
            loading:false,

            formTpUnidadMedida: {
                IdTpUnidadMedida:0,
                NbTpUnidadMedida:'',
                FgActTpUnidad:true,
            }, 
        }
    }

    //methods
    function cambio(e){
        this.formUnidadMedida.FgActTpUnidad = !this.FgActTpUnidad.FgActTpUnidad;
    }
    function closeModal(){
        //this.$nextTick(() => { this.$v.formTpUnidadMedida.$reset() })

        if(this.apiStateLoading || this.loading)
            return
        this.$store.commit('unidadmedida/mutationModalUnitMeasureType', false);

        this.$v.formTpUnidadMedida.$reset();
    }
    function registerData(){
       
       try { 
            this.$v.formTpUnidadMedida.$touch();
            if (this.$v.formTpUnidadMedida.$pending || this.$v.formTpUnidadMedida.$error)
            throw this.$t('label.errorsPleaseCheck');
            
            if (this.$v.formTpUnidadMedida.$pending || this.$v.formTpUnidadMedida.$error) return;

            let { IdTpUnidadMedida,NbTpUnidadMedida,FgActTpUnidad } = this.formTpUnidadMedida;
            const { dispatch } = this.$store;

            if(this.originalAct !== '' && IdTpUnidadMedida !== 0){
                if(this.originalAct !== FgActTpUnidad){              
                        this.$swal
                            .fire(this.alertProperties({
                                text: `${this.$t('label.changeStatusQuestion')+' '} ${this.formTpUnidadMedida.NbTpUnidadMedida}?`,
                            }))     
                        .then((result) => {
                        if (result.isConfirmed) {
                            dispatch('unidadmedida/postregisterTipoUnidad', { IdTpUnidadMedida,NbTpUnidadMedida,FgActTpUnidad });
                            this.$v.formTpUnidadMedida.$reset();

                        }
                    })
                }else{
                    dispatch('unidadmedida/postregisterTipoUnidad', { IdTpUnidadMedida,NbTpUnidadMedida,FgActTpUnidad });
                    this.$v.formTpUnidadMedida.$reset();

                }
            }else{
                dispatch('unidadmedida/postregisterTipoUnidad', { IdTpUnidadMedida,NbTpUnidadMedida,FgActTpUnidad });
                this.$v.formTpUnidadMedida.$reset();
            }

        } catch (e) {
            this.notifyError({ text: e });
        }
        
    }


    //computed

    function idIndentification(){
        return this.formTpUnidadMedida.IdTpUnidadMedida === 0;
    }
    function apiStateLoading() {
        return this.apiState === ENUM.LOADING;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING || this.apiStateForm === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    
    function selectOptions() {
        return[
        {
            value: true,
            label: this.$t('label.ACTIVO'),
        },
        {
            value: false,
            label: this.$t('label.INACTIVO'),
        },
        ]
    }

    //watch
    function modalUnitMeasureType(newQuestion,Oldquestion){
        if(newQuestion === false){
            this.formTpUnidadMedida.IdTpUnidadMedida = 0;
            this.formTpUnidadMedida.NbTpUnidadMedida = '';
            this.formTpUnidadMedida.FgActTpUnidad = true;
            this.originalAct = '';
           // this.$nextTick(() => { this.$v.$reset() });

            this.$store.commit('unidadmedida/setUnitMeasureTypeId', 0);
        }else{
            this.busqueda(this.idValor);
           // this.$store.dispatch('unidadmedida/getTipoUnidadlist');
        }
    }
 
 
     function busqueda(newQuestion){
       // if(this.collapseTypeUnitMeasure){
            if(newQuestion !== 0){
                this.$store.state.unidadmedida.apiStateForm = ENUM.LOADING;
                try {
                    this.$http.ejecutar('GET', 'TpUnitMeasure-by-id',{ TpUnitMeasureId: newQuestion }, '' ).then(response => {
                        try {
                            if(response.status === 200){
                                const UnidadInformation = response.data.data;
                                if(UnidadInformation.length !== 0){
                                    this.formTpUnidadMedida.IdTpUnidadMedida = UnidadInformation[0].TpUnitMeasureId;
                                    this.formTpUnidadMedida.NbTpUnidadMedida = UnidadInformation[0].TpUnitMeasureName;
                                    this.formTpUnidadMedida.FgActTpUnidad = UnidadInformation[0].FgActTpUnitMeasure;
                                    this.originalAct = UnidadInformation[0].FgActTpUnitMeasure;
                                    this.$v.formTpUnidadMedida.$touch();

                                    this.$store.state.unidadmedida.apiStateForm = ENUM.INIT;
                                }else{
                                    this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                                    this.$store.commit('unidadmedida/mutationModalUnitMeasureType', false);
                                }
                            }else{
                                this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                                this.$store.commit('unidadmedida/mutationModalUnitMeasureType', false);
                            }
                        } catch (err) {
                            this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                            this.$store.commit('unidadmedida/messageMutation', err);
                            this.$store.commit('unidadmedida/mutationModalUnitMeasureType', false);
                        }
                    }).catch(err => {
                        this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                        this.$store.commit('unidadmedida/messageMutation', err);
                        this.$store.commit('unidadmedida/mutationModalUnitMeasureType', false);
                    });
                } catch (error) {
                    this.$store.state.unidadmedida.apiStateForm = ENUM.ERROR;
                    this.$store.commit('unidadmedida/messageMutation', error);
                    this.$store.commit('unidadmedida/mutationModalUnitMeasureType', false);
                }
            }
      //  }
    }

    export default {
        name: 'modal-unit-measure-type',
        data,
        mixins:[general, modalMixin],
        props: {
            titleModalUnitMeasureType:{ 
                type: String,
                default: false,
            }
        },
        components: {
           
        },
        directives: UpperCase,
        validations(){ return FormTpUnidadMedida() },
        computed: { 
            idIndentification,
            apiStateLoading,
            apiStateFormLoading,
            selectOptions,
            ...mapState({
                idValor: state => state.unidadmedida.unitMeasureTypeId,
                idState: state=> state.unidadmedida.id,
                modalUnitMeasureType: state=> state.unidadmedida.modalUnitMeasureType,
                apiState: state => state.unidadmedida.apiState,
                apiStateForm: state => state.unidadmedida.apiStateForm
            })
        },
        methods:{
            cambio,
            closeModal,
            registerData,
            busqueda,
        },
        watch:{
            modalUnitMeasureType,
            /*idValor: {
               deep:true,
               handler:busqueda
            },*/
        }
    }
</script>